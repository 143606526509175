import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Thread from "../../components/thread"

import { fillTemplate, numRoundoff, hyphenatedName } from "../../services/format"
import { ThreadT } from "../../services/seotemplate"

import "../../styles/global.css"

const Threads = ({ data }) => {
  const thread = data.allBookThreads.edges[0].node;
  const question = thread.question[0]
  const woa = thread.woa
  const image = woa[0]?.books[0]?.thumbnail
  const templateVars = {}
  
  templateVars.question = question.text
  templateVars.image = image
  
  let title = 'Book Recommendations' 
  const description = fillTemplate(ThreadT.description, templateVars)

  if(question.hasOwnProperty('keyword') && question.keyword !== null){
    templateVars.keyword = question.keyword
    title = fillTemplate(ThreadT.title, templateVars)
  }

  const schema = 
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": title,
      "description": description
    }  

  return(
      <Layout>
          <SEO title={title} description={description} image={image}/>
          <Thread parentTweet={question} woa={woa}/> 
      </Layout>
  )
}

export const query = graphql`
query($parentTweetID: String!){
  allBookThreads(filter: {question: {elemMatch: {tweet_id_str: {eq: $parentTweetID}}}}) {
    edges {
      node {
        question {
          text
          user_name
          user_screen_name
          created_at
          tweet_id_str
          keyword
        }
        woa {
          woa_str
          score
          books {
            isbn
            affiliateLink
            title
            thumbnail
            author
            amazonLink
            indieboundLink
            description
            geniusLink
          }
          num_votes
          num_likes
        }
      }
    }
  }
}
`

export default Threads